import React, {PureComponent } from 'react'
import Fade from 'react-reveal/Fade';
//import MapGL, { FlyToInterpolator, Marker } from 'react-map-gl'
//import WebMercatorViewport from 'viewport-mercator-project'

// 'mapbox://styles/antoniosf/cjqz296pl0n3g2ro1m8eh5k9s'
//const mapboxToken =
//  'pk.eyJ1IjoiYW50b25pb3NmIiwiYSI6ImNqcDJrbWJ0bDA3amMzcGxrMDMwY3Z0OWEifQ.NI4dWHlRMp-4sBgMMP_sIg'

// 38.7127586 ,-9.1526902
/*const viewport = new WebMercatorViewport({
  width: window.innerWidth,
  height: window.innerHeight / 1.5,
  latitude: 38.70265,
  longitude: -9.182993,
  zoom: 16,
  bearing: 0,
  pitch: 0
})*/

class Fiesta extends PureComponent {
  /*constructor(props) {
    super(props)

    this.state = {
      mobile: false,
      showMarker: true,
      viewport,
      transitionVelocity: 500,
      cursor: {
        x: 0,
        y: 0,
        hoveredObject: null
      }
    }
  }

  isMobile = () => {
    this.setState(
      {
        mobile: window.matchMedia('(max-width: 1024px)').matches
      },
      () => {
        setTimeout(() => this.handleMapDimensions(), 200)
      }
    )
  }

  componentDidMount() {
    this.isMobile()
    window.addEventListener('resize', this.isMobile)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.isMobile)
  }

  handleMapDimensions = () => {
    const { mobile } = this.state

    this.setState(prevState => ({
      viewport: {
        ...prevState.viewport,
       width: mobile ? window.innerWidth : window.innerWidth / 2,
        height: window.innerHeight / 1.5
      }
    }))
  }*/

  render() {
    //const { viewport, transitionVelocity } = this.state

    return (
      <section className="Fiesta">
        <div className="Fiesta-container">
        <Fade><p className="Fiesta-title">Quinta da Serralheira</p></Fade>
          <div className="Fiesta-flex">
          <Fade><div className="Fiesta-map">
            {/*<MapGL
              {...viewport}
              mapboxApiAccessToken={mapboxToken}
              transitionDuration={transitionVelocity}
              transitionInterpolator={new FlyToInterpolator()}
              onTransitionEnd={() => this.handleTransitionEnd()}
              mapStyle={'mapbox://styles/antoniosf/cjwhypfus0td11co87trtvqcx'}
              style={{width: '50%'}}
            >

                <Marker
                  latitude={viewport.latitude}
                  longitude={viewport.longitude}
                  captureScroll={false}
                >
                  <img
                    className="Story-marker"
                    src="/media/custom_marker.png"
                    alt=""
                  />
                </Marker>

    </MapGL>*/}
          </div></Fade>
          <div className="Fiesta-text">
            <Fade delay={500}>
            <div className="Fiesta-details">
              <img src="./media/new-2/icon-rings.png" className="Fiesta-details-icon" width="50" height="50" alt="Church" />
              <div className="Fiesta-details-text">
                <p className="Fiesta-details-title">Cerimônia</p>
              </div>
            </div></Fade>
            <Fade delay={550}><div className="Fiesta-details">
              <div className="Fiesta-details-text">
                <p className="Fiesta-details-title">Cocktail</p>
              </div>
              <img src="./media/new-2/icon-glasses.png" className="Fiesta-details-icon" width="50" height="50" alt="Church" />
            </div></Fade>
            <Fade delay={600}><div className="Fiesta-details">
              <img src="./media/new-2/icon-trait.png" className="Fiesta-details-icon" width="150" height="150" alt="Church" />
              <div className="Fiesta-details-text">
                <p className="Fiesta-details-title">Jantar</p>
              </div>
            </div></Fade>
            <Fade delay={650}><div className="Fiesta-details Fiesta-details-last">
              <div className="Fiesta-details-text">
                <p className="Fiesta-details-title">Festa</p>
              </div>
              <img src="./media/new-2/icon-crystal-ball.png" className="Fiesta-details-icon" width="50" height="50" alt="Church" />
            </div></Fade>
          </div>
          </div>
          </div>
      </section>
    )
  }
}

export default Fiesta
