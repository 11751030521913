import React from 'react'
import Fade from 'react-reveal/Fade';
import { animateScroll as scroll } from 'react-scroll'

const scrollToElement = () => {
  scroll.scrollTo(window.innerHeight, {
    duration: 1000,
    smooth: 'easeInOutQuart'
  })
}

const Intro = () => (
  <section className="Intro">
    <div className="Intro-container">
      <div className="Intro-text-wrapper">
        <Fade delay={500}>
          <p className="Intro-subtitle">Rita e Zé</p>
        </Fade>
        <Fade delay={800}>
          <p className="Intro-date">25.05.2024 - 17h00</p>
        </Fade>
        <Fade delay={1000}>
          <p className="Intro-address">
          <a className="Intro-address" href="https://www.google.com/maps/dir//Estrada+das+Serralheiras,+Serralheira,+2950-318+Palmela/@38.590006,-8.9519359,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0xd1941bd4baa5a17:0xd3483f6d71d90452!2m2!1d-8.8695349!2d38.5900349?entry=ttu"
                  target="_blank"
                  rel="noopener noreferrer"
                >Estrada das Serralheiras, Serralheira<br /> 2950-318 Palmela</a></p>
        </Fade>
      </div>

      <div className="Intro-image-wrapper">
        <Fade>
          <img src="./media/new-2/cover.jpeg" className="Intro-banner" alt="Rita e Zé" width="2397" height="3575"  />
        </Fade>
      </div>
    </div>

    {/*<img src="./media/new/arrow-alt.png" className="Intro-arrow" width="20" height="20" alt="scroll down" />*/}
    <img src="./media/new-2/flowers-cover.png" className="Intro-flowers" alt="flowers"  />
    
   {/* <svg
      onClick={scrollToElement}
      className="Intro-arrow"
      width="60px"
      height="80px"
      viewBox="0 0 50 80"
    >
      <polyline
        fill="none"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="
	0.375,0.375 45.63,38.087 0.375,75.8 "
      />
</svg>*/}
  </section>
)

export default Intro
