import React, { useState } from 'react'
import Fade from 'react-reveal/Fade';
import { useForm, ValidationError } from '@formspree/react';

const baseUrl = "https://calendar.google.com/calendar/render?action=TEMPLATE"
const title = "Casamento+Rita+e+Zé"
const dates = "20240525T150000Z/20240526T030000Z"
const details = "O Casamento terá inicio às 17h00 do dia 25 de Maio.<br /><br /> Para Presentes: <br /><b>IBAN</b> - PT50 0193 0000 1051 0579 9145 1<br /><br /><b>website</b> - https://ritaeze.pt/"
const location = "Estrada+das+Serralheiras,+Serralheira"
const ctz = "Europe/Lisbon"

const finalUrl = `${baseUrl}&text=${title}&dates=${dates}&ctz=${ctz}&details=${details}&location=${location}`

const FormEl = () =>  {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [comments, setComments] = useState('')
  const [state, handleSubmit] = useForm("mleqparb");


  const handleChange = e => {
    setName( e.currentTarget.value)
  }

  const handleEmailChange = e => {
    setEmail(e.currentTarget.value)
  }

  const handleCommentsChange = e => {
    setComments( e.currentTarget.value)
  }

    return (
      <div className="FormEl">
        <div className="FormEl-container">
        <Fade><h2 className="FormEl-title">
        Queremos muito ter-te <br /> connosco!
          </h2></Fade>

          {state.succeeded ? (
            <Fade>
              <div className="FormEl-success">
                <p className="FormEl-subtitle">Obrigado pela confirmação,<br /> esperamos por vocês dia 25 de Maio!</p>
                <a href={finalUrl} rel="noopener noreferrer" target="_blank" className="FormEl-success-link">
                  <img width="24" height="24" src="./media/new/calendar.svg" />Marcar no calendário
                </a>

              </div>
            </Fade>
          ) : (
          <Fade delay={500}>
          <form
            className="FormEl-form"
            onSubmit={handleSubmit}>
            <textarea
              className="FormEl-input"
              type="text"
              name="name"
              placeholder="Deixa o teu nome! &#10; 
              Se tiveres crianças, deixa o nome de todos!"
              onChange={handleChange}
              value={name}
              required
            />
            <input
              className="FormEl-input"
              type="email"
              name="email"
              placeholder="Deixa o teu email"
              onChange={handleEmailChange}
              value={email}
              required
            />
            <textarea
              className="FormEl-input"
              type="text"
              name="comments"
              placeholder="Tens restrições alimentares, 
              alergias ou outras? Deixa aqui"
              onChange={handleCommentsChange}
              value={comments}
            />
            <button className="FormEl-button" type="submit">
              Confirmar presença
            </button>
          </form>
          </Fade>)}
        </div>
      </div>
    )
}

export default FormEl
