import React from 'react'
import Intro from '../components/intro.jsx'
import BoardMood from '../components/boardmood.jsx'
import Fiesta from '../components/fiesta.jsx'
import Gifts from '../components/gifts.jsx'
import FormEl from '../components/form.jsx'
import Video from '../components/video.jsx'

const Home = () => (
  <div className="Home">
    <Intro />
    <Fiesta />
    <BoardMood />
    <FormEl />
    <Gifts />
  </div>
)

export default Home
