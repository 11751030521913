import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import Home from './containers/home.jsx'

const App = () => (
  <main className="main-content">
    <Home />
  </main>
)

ReactDOM.render(<App />, document.getElementById('app'))
