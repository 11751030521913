import React, {useState} from 'react'
import ReactTooltip from 'react-tooltip';
import Fade from 'react-reveal/Fade';

const Gifts = () => {
  const [fooRef, setFooRef] = useState()
  const [barRef, setBarRef] = useState()
  const [content, setContent] = useState('Click to copy')

  const handleCopyClick = () => {
    const text = "+351966612181"
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text).then(() => {
        setContent('Copied')
        ReactTooltip.show(barRef)
      }).then(() => {
        setTimeout(() => {
          ReactTooltip.hide(barRef)
          setContent('Click to copy')
        }, 1000)
      });
    }
  }

  const handleIbanCopyClick = () => {
    const text = "PT50019300001051057991451"
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text).then(() => {
        setContent('Copied')
        ReactTooltip.show(fooRef)
      }).then(() =>{
        setTimeout(() => {
          ReactTooltip.hide(fooRef)
          setContent('Click to copy')
        }, 1000)
      });
    }
  }

  return (
  <>
  <section className="Gifts">
    <div className="Gifts-container">
      <div className="Gifts-text">
      <Fade><p className="Gifts-title">Presentes</p></Fade>
      <Fade delay={500}><p className="Gifts-subtitle">O melhor presente serás sempre tu na nossa vida e neste dia!<br />

      Se quiseres contribuir para a nossa lua de mel,<br /> vamos deixar-te um bocadinho do nosso plano:</p></Fade>
      </div>

      <Fade delay={550}>
        <img src="./media/new-2/icon-map.png" className="Gifts-picture" width="250" height="250" alt="Around the world" />
      </Fade>
      <div className="Gifts-text">
      <Fade delay={500}><p className="Gifts-subtitle">A nossa rotina é sempre muito cheia, somos 5, muitos planos, responsabilidades mas acima de tudo muito amor e realização. No entanto precisamos também de cultivar o nosso amor e decidimos ir juntos numa aventura pela indonésia!
Vamos de mochilas as costas explorar uma cultura que esperamos que nos acrescente mais uma bonita história à nossa vida!<br /><br />Podes transferir para o seguinte IBAN:</p></Fade></div>
    </div>
    <div className="Gifts-payment-wrapper">
      <div className="Gifts-payment">
      <div className="Gifts-iban" onClick={handleIbanCopyClick}>
          <p className="Gifts-subtitle Gifts-subtitle-iban"><span>PT50 0193 0000 1051 0579 9145 1</span></p>
          <div className="Gifts-copy" ref={ref => setFooRef(ref)} data-tip={content} data-type="light">
              <img src="./media/new/copy.png" width="24" heigh="24" alt="copy" />
              <span>Copiar</span>
            </div>
        </div>
      </div>
      <ReactTooltip />
    </div>
  </section>
      <div className="Gifts-text">
        <Fade><p className="Gifts-title">25.05.2024</p></Fade>
        <Fade delay={500}><p className="Gifts-subtitle">MARCA ESTE DIA NA TUA AGENDA<br /> E VEM DESFRUTAR DE UM DOS DIAS MAIS BONITOS DA NOSSA VIDA CONNOSCO!</p></Fade>
        <Fade delay={550}>
        <p className="Gifts-subtitle"><img src="./media/new-2/last.png" className="Gifts-picture-2" width="450" height="600" alt="Rita e Zé" /></p>
      </Fade>
      </div>
  </>
)
}

export default Gifts
