import React from 'react'
import Fade from 'react-reveal/Fade';

const BoardMood = () => (
  <section className="BoardMood">
    <div className="BoardMood-container">
    
        <img className="BoardMood-flowers-1" src="./media/new-2/flowers.png" />
      
      <div className="BoardMood-text">
      <Fade><p className="BoardMood-title">A nossa história</p></Fade>
      <img className="BoardMood-line" src="./media/new-2/line.png" />
      </div>
      <Fade delay={550}><img src="./media/new-2/gallery.png" className="BoardMood-picture" width="1024" height="1280" alt="Board mood" /></Fade>
      <img className="BoardMood-flowers-2" src="./media/new-2/flowers-2.png" />
    </div>
  </section>
)

export default BoardMood
